import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from "@/auth";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/Base.vue'),
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        name: 'Top',
        redirect: '/users',
      },
      {
        path: 'users',
        name: 'Users',
        component: () => import('@/views/Users.vue')
      },
      {
        path: 'users/:id',
        name: 'User',
        props: true ,
        component: () => import('@/views/User.vue')
      },
      {
        path: 'roles',
        name: 'Roles',
        component: () => import('@/views/Roles.vue')
      },
      {
        path: 'applicants',
        name: 'Applicants',
        component: () => import('@/views/Applicants.vue')
      },
      {
        path: 'roles/:id/users',
        name: 'UsersByRole',
        props: true ,
        component: () => import('@/views/UsersByRole.vue')
      },
      {
        path: 'applicants/:id/users',
        name: 'UsersByApplicant',
        props: true ,
        component: () => import('@/views/UsersByApplicant.vue')
      },
      {
        path: 'users-imports',
        name: 'BulkImportUsers',
        component: () => import('@/views/BulkImportUsers.vue')
      },
      {
        path: 'users-imports-xlsx',
        name: 'BulkImportUsersXlsx',
        component: () => import('@/views/BulkImportUsersXlsx.vue')
      },
      {
        path: 'roles-imports',
        name: 'ImportRoles',
        component: () => import('@/views/ImportRoles.vue')
      },
    ]
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import('@/views/Unauthorized.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router